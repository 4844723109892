import ProtectedRoute from "@/components/ProtectedRoute"
import { hasUnityPermission } from "@/util/permissions"
import i18next from "i18next"
import { lazy } from "react"
import { RouteObject } from "react-router-dom"

const CampaignGoals = lazy(
  () => import("@/pages/UnityTest/CampaignGoals/CreateCampaign"),
)

const route: RouteObject = {
  path: "/unity-test/create-campaign",
  handle: {
    pageTitle: () =>
      `${i18next.t("pageTitle", { ns: "unityTest" })} - ${i18next.t(
        "label.hitseeker",
        { ns: "common" },
      )}`,
    title: () => i18next.t("label.createCampaign", { ns: "unityTest" }),
    crumb: () => i18next.t("label.createCampaign", { ns: "unityTest" }),
  },
  element: (
    <ProtectedRoute hasPermission={hasUnityPermission} redirectTo="/">
      <CampaignGoals />
    </ProtectedRoute>
  ),
}

export default route
